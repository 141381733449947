import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useLocation } from 'react-router-dom';
import Chat from './components/Chat';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from './pages/login';
import Register from './pages/register';
import Prospects from './pages/prospects';
import MembershipPlan from './pages/MembershipPlan';
import AccountSettings from './pages/AccountSettings';
import Blog from './pages/Blog'; // Import the Blog component
import ProfileDropdown from './components/ProfileDropdown';

function Header({ isAuthenticated, handleLoginShow, handleSignupShow, handleLogout }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <header className="header">
      <div className="title-container">
        <h1>
          <Link to="/" className="logo-link">
           <span className="logo-reno">reno</span>
           <span className="logo-bot">bot</span>
           <span className="beta-text"> (beta)</span>
          </Link>
        </h1>
        {isHomePage && (
          <>
            <p>
              <strong>Homeowners:</strong> Looking to upgrade, renovate, or fix your home? Chat with our AI agent below and <Link to="/blog">check our blog</Link>.
            </p>
            <p>
              <strong>Contractors:</strong> Looking for project leads, potential customers? <Link to="/register">Signup</Link> to our waiting list.
            </p>
          </>
        )}
      </div>
      <div className="profile-icon">
        <ProfileDropdown 
          isAuthenticated={isAuthenticated}
          handleLoginShow={handleLoginShow}
          handleSignupShow={handleSignupShow}
          handleLogout={handleLogout}
        />
      </div>
    </header>
  );
}

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [authMessage, setAuthMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLoginShow = () => setShowLogin(true);
  const handleLoginClose = () => setShowLogin(false);
  const handleSignupShow = () => setShowSignup(true);
  const handleSignupClose = () => setShowSignup(false);

  const handleLoginSuccess = (token, userId) => {
    setIsAuthenticated(true);
    setShowLogin(false);
    localStorage.setItem('token', token); // Save token to localStorage
    localStorage.setItem('userId', userId); // Save userId to localStorage
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
  };

  return (
    <Router>
      <div className="App">
        <Header 
          isAuthenticated={isAuthenticated}
          handleLoginShow={handleLoginShow}
          handleSignupShow={handleSignupShow}
          handleLogout={handleLogout}
        />
        <Routes>
          <Route path="/" element={<Chat />} />
          <Route path="/blog" element={<Blog />} /> {/* Add the blog route */}
          <Route path="/prospects" element={isAuthenticated ? <Prospects /> : <Navigate to="/" />} />
          <Route path="/membershipplan" element={isAuthenticated ? <MembershipPlan /> : <Navigate to="/" />} />
          <Route path="/accountsettings" element={isAuthenticated ? <AccountSettings /> : <Navigate to="/" />} />
          <Route path="/register" element={<Register show={true} handleClose={handleSignupClose} setAuthMessage={setAuthMessage} />} />
        </Routes>

        {authMessage && <div className="auth-message">{authMessage}</div>}

        <Login show={showLogin} handleClose={handleLoginClose} setAuthMessage={setAuthMessage} onSuccess={handleLoginSuccess} />
        <Register show={showSignup} handleClose={handleSignupClose} setAuthMessage={setAuthMessage} />
      </div>
    </Router>
  );
}

export default App;
