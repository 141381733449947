import React, { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa'; // Import the user icon

const ProfileDropdown = ({ isAuthenticated, handleLoginShow, handleSignupShow, handleLogout }) => {
  const navigate = useNavigate();

  // Custom Toggle Component
  const CustomToggle = forwardRef(({ onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
    >
      <FaUserCircle size={30} />
    </div>
  ));

  return (
    <Dropdown align="end" drop="start">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-basic" />
      <Dropdown.Menu className="dropdown-menu-end">
        {isAuthenticated ? (
          <>
            <Dropdown.Item onClick={() => navigate('/prospects')}>Prospects</Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/membershipplan')}>Membership Plan</Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/accountsettings')}>Account Settings</Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item onClick={handleLoginShow}>Contractor Login</Dropdown.Item>
            <Dropdown.Item onClick={handleSignupShow}>Contractor Signup</Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
