// src/pages/register.js
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = ({ show, handleClose, setAuthMessage }) => {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    zip: '',
    state: '',
    website: '',
    isContractor: 'yes',
  });
  
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
     const response = await axios.post(`${API_BASE_URL}/api/auth/prospect_contractors`, formData);
     setAuthMessage(response.data.message);
     handleClose();
     navigate('/'); // Navigate back to the home page
    } catch (error) {
      alert('Error joining the waitlist');
    }
  };

  return (
    <Modal show={show} onHide={() => { handleClose(); navigate('/'); }}>
      <Modal.Header closeButton>
        <Modal.Title>Join Waitlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Form fields */}
          <Form.Group controlId="formBasicName">
            <Form.Control
              type="text"
              name="name"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicLastname">
            <Form.Control
              type="text"
              name="lastname"
              placeholder="Enter last name"
              value={formData.lastname}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPhone">
            <Form.Control
              type="text"
              name="phone"
              placeholder="Enter phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicZIP">
            <Form.Control
              type="text"
              name="zip"
              placeholder="Enter ZIP"
              value={formData.zip}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicState">
            <Form.Control
              type="text"
              name="state"
              placeholder="Enter state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicWebsite">
            <Form.Control
              type="text"
              name="website"
              placeholder="Enter website (optional)"
              value={formData.website}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Are you a contractor?</Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                name="isContractor"
                value="yes"
                label="Yes"
                checked={formData.isContractor === 'yes'}
                onChange={handleChange}
              />
              <Form.Check
                inline
                type="radio"
                name="isContractor"
                value="no"
                label="No"
                checked={formData.isContractor === 'no'}
                onChange={handleChange}
              />
            </div>
          </Form.Group>
          <Button variant="primary" type="submit">
            Join Waitlist
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Register;
