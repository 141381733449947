// src/pages/prospects.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { jwtDecode } from 'jwt-decode';
import './prospects.css';

const stateOptions = [
    { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

const highEndEstimateOptions = [
   { value: 'lessThan20K', label: 'Less Than 20K' },
  { value: '20Kto50K', label: '20K to 50K' },
  { value: '50Kto100K', label: '50K to 100K' },
  { value: '100Kplus', label: '100K+' }
];

const Prospects = () => {
  const [chatLogs, setChatLogs] = useState([]);
  const [stateFilter, setStateFilter] = useState([]);
  const [zipFilter, setZipFilter] = useState('');
  const [radiusFilter, setRadiusFilter] = useState(20); // Default to 20 miles
  const [estimateFilter, setEstimateFilter] = useState([]);
  const radiusOptions = [20, 30, 50, 120];

  useEffect(() => {
    fetchChatLogs();
  }, []);

  const fetchChatLogs = async () => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token); // Corrected import usage
      const response = await axios.get(`${API_BASE_URL}/api/chatlogs/logs`, {
        params: {
          userId: decodedToken.userId,
        },
      });
      setChatLogs(response.data);
    } catch (error) {
      console.error('Error fetching chat logs:', error);
    }
  };

  const applyFilters = async () => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token); // Corrected import usage
      const response = await axios.get(`${API_BASE_URL}/api/chatlogs/logs`, {
        params: {
          userId: decodedToken.userId,
          state: stateFilter.map(option => option.value).join(','),
          zip: zipFilter,
          radius: radiusFilter,
          estimate: estimateFilter.join(',')
        },
      });
      setChatLogs(response.data);
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  };

  const handleRadiusChange = (e) => {
    setRadiusFilter(radiusOptions[e.target.value]);
  };

  const handleEstimateChange = (selectedOptions) => {
    setEstimateFilter(selectedOptions.map(option => option.value));
  };

  return (
    <div className="project-leads">
      <h1>Prospect Customers</h1>
      <div className="filters">
        <div className="filter-row">
          <Select
            isMulti
            name="states"
            options={stateOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select states..."
            onChange={setStateFilter}
          />
          <input
            type="text"
            placeholder="ZIP Code"
            value={zipFilter}
            onChange={(e) => setZipFilter(e.target.value)}
            className="filter-input"
          />
          <div className="slider-container">
            <label>Radius (miles): {radiusFilter}</label>
            <input
              type="range"
              min="0"
              max="3"
              step="1"
              value={radiusOptions.indexOf(radiusFilter)}
              onChange={handleRadiusChange}
              className="filter-input"
            />
          </div>
        </div>
        <div className="filter-row">
          <div className="estimate-filter">
            <Select
              isMulti
              name="highEndEstimates"
              options={highEndEstimateOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="AI Estimate"
              onChange={handleEstimateChange}
            />
          </div>
        </div>
        <button onClick={applyFilters} className="filter-button">Apply Filters</button>
      </div>
      <div className="chat-logs">
        {chatLogs.map((log, index) => (
          <div key={index} className="chat-log">
            <p><strong>Name:</strong> {log.name}</p>
            <p><strong>Email:</strong> {log.email}</p>
            <p><strong>ZIP Code:</strong> {log.zip}</p>
            <p><strong>Phone:</strong> {log.phone}</p>
            <p><strong>AI Estimate:</strong> {log.aiEstimate}</p>
            <p><strong>Summary:</strong> {log.summary}</p>
            <p><strong>Created At:</strong> {log.createdAt}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Prospects;