import React from 'react';

const MembershipPlan = () => {
  return (
    <div>
      <h2>Membership Plan</h2>
      <p>Details about membership plans will be available soon.</p>
    </div>
  );
};

export default MembershipPlan;
