import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AccountSettings = () => {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    zip: '',
    state: '',
    website: '',
    password: '',
  });
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('http://localhost:5000/api/auth/user', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setFormData({
          name: response.data.name,
          lastname: response.data.lastname,
          email: response.data.email,
          phone: response.data.phone,
          zip: response.data.zip,
          state: response.data.state,
          website: response.data.website,
          password: '', // Do not pre-fill password
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.put('http://localhost:5000/api/auth/user', formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      alert('Account settings updated successfully');
    } catch (error) {
      console.error('Error updating account settings:', error);
      alert('Failed to update account settings');
    }
  };

  const handleBackToDashboard = () => {
    navigate('/prospects'); // Navigate to the prospects page
  };

  return (
    <div>
      <h2>Account Settings</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Enter name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="lastname"
          placeholder="Enter last name"
          value={formData.lastname}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Enter email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Enter phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="zip"
          placeholder="Enter ZIP"
          value={formData.zip}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="state"
          placeholder="Enter state"
          value={formData.state}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="website"
          placeholder="Enter website"
          value={formData.website}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Enter new password"
          value={formData.password}
          onChange={handleChange}
        />
        <button type="submit">Update</button>
      </form>
      <button onClick={handleBackToDashboard}>Back to Dashboard</button>
    </div>
  );
};

export default AccountSettings;
